<template lang="pug">
.page.page-with-table.versions-page
  template(v-if="isAdmin")
    .versions-page-header
      .versions-page-header__title Версии
      it-button.versions-page-header__btn(type="primary" @click="modal = true") Создать версию

    data-table.versions-page__table.page__table(
      :loading="loading" :headers="headers" :items="versions" modelName="version"
      :editApiMethod="editApiMethod" :editApiUrl="editApiUrl" @update:item="updateVersion"
      :deleteApiMethod="deleteApiMethod" :deleteApiUrl="deleteApiUrl" @delete:item="deleteVersion")
    version-form-modal(v-model="modal" @version:create="addVersion")


  forbidden-modal(:dialog="!isAdmin")
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import DataTable from '@/components/DataTable'
import VersionFormModal from '@/components/VersionFormModal'


export default {
  name: 'VersionsPage',
  components: { DataTable, VersionFormModal },
  data() {
    return {

      loading: false,
      modal: false,

      versions: [],
      headers: [
        {title: 'id', value: 'id', readonly: true, sortable: true},
        {title: 'Название', value: 'name'},
        {title: 'Версия', value: 'version', sortable: true},
        {title: 'Дата', value: 'createdDate', sortable: true},
        {title: 'isLast', value: 'isLast', boolean: true}
      ],

      editApiMethod: 'patch',
      editApiUrl: '/versions',
      deleteApiMethod: 'delete',
      deleteApiUrl: '/versions'

    }
  },

  methods: {
    async getVersions() {
      this.loading = true

      try {
        const {data} = await this.$api.get('/versions')
        this.versions = data.result.data
      } catch (err) {
        console.log(err)
        this.$notification.error( err?.response?.data?.message || err.message )
      }

      this.loading = false
    },

    addVersion(version) {
      if ( version.isLast ) {
        const isLastVersion = this.versions.find( elem => elem.isLast === true )
        isLastVersion.isLast = false
      }
      this.versions = [ ...this.versions, version ]
    },

    updateVersion(version) {
      const index = this.versions.findIndex( elem => elem.id === version.id )
      this.versions[index] = { ...version }
    },

    deleteVersion(id) {
      const index = this.versions.findIndex( elem => elem.id === id )
      this.versions.splice(index, 1)
    }
  },

  mounted() {
    this.getVersions()
  }
}
</script>