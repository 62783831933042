<template lang="pug">
it-modal(v-model="modelValue")
  template(#header) Создать версию

  template(#body)
    form.version-form(@submit.prevent="submit" id="version")
      label.version-form__label
        span.version-form__label-text Название
        it-input.version-form__label-input(v-model="version.name" required)
      label.version-form__label
        span.version-form__label-text Версия
        it-input.version-form__label-input(v-model="version.version" required)
      label.version-form__label
        span.version-form__label-text Дата создания
        it-input.version-form__label-input(v-model="version.createdDate" type="date" required)
      label.version-form__label.version-form__switch-label
        span.version-form__label-text isLast
        it-switch.version-form__label-input(v-model="version.isLast")

  template(#actions)
    it-button(type="danger" outlined @click="$emit('update:modelValue', false)") Отменить
    it-button(type="primary" form="version" :loading="loading") Создать
</template>

<style lang="sass" scoped src="./index.sass"></style>


<script>
import emptyVersion from '@/assets/emptyVersion'


export default {
  name: 'VersionFormModal',
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue', 'version:create'],
  data() {
    return {

      loading: false,
      version: { ...emptyVersion }

    }
  },
  
  watch: {
    modelValue() {
      this.$emit('update:modelValue', this.modelValue)
      if ( !this.modelValue ) this.version = { ...emptyVersion }
    }
  },

  methods: {
    async submit() {
      if ( this.loading ) return
      this.loading = true

      try {
        const {data} = await this.$api.post('/versions', { version: this.version })
        this.$notification.success('Успешно создано')
        this.$emit('version:create', data.result.version)
        this.$emit('update:modelValue', false)
      } catch (err) {
        console.log(err)
        this.$notification.error( err?.response?.data?.error || err.message )
      }

      this.loading = false
    }
  }
}
</script>